export const CLASSES = {
    STRIKE_HEADER: 'grid__strike-header',
    STRIKE_CELL: 'grid__strike-cell',
    GRID_FULL_WIDTH: 'grid__full-width',
    GRID_ROWS_IN: 'grid__row-in',
    GRID_ADD_STRIKE: 'grid__half-width-add-strike',
    GRID_MINUS_STRIKE: 'grid__half-width-minus-strike',
    GREEK_CELL: 'grid__greeks-cell',
    GREEK_CELL_CONTENT: 'grid__greeks-cell-content'
};
