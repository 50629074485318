var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "markets-ui-container",
    {
      class: _vm.namespace(),
      attrs: {
        skin: _vm.watchedSettings.skin,
        errorMessage: _vm.errorMessage,
        showBorder: _vm.watchedSettings.showBorder,
        showHeader: _vm.watchedSettings.showHeader,
        showSetting: _vm.watchedSettings.showSetting,
        showLoading: _vm.showLoading
      },
      on: { "setting-click": _vm.toggleSettings }
    },
    [
      _c(
        "div",
        {
          ref: "content",
          class: _vm.namespace("content"),
          attrs: { slot: "content" },
          slot: "content"
        },
        [
          !_vm.errorMessage
            ? _c(
                "div",
                { ref: "menu", class: _vm.namespace("menu") },
                [
                  _c(
                    "markets-ui-fieldset",
                    {
                      class: _vm.namespace("menu-type"),
                      attrs: {
                        skin: _vm.watchedSettings.skin,
                        legend: _vm.initedLabels.type
                      }
                    },
                    [
                      _c("markets-ui-menus", {
                        attrs: {
                          skin: _vm.watchedSettings.skin,
                          dataModel: _vm.typesDataModel,
                          "resize-element": _vm.$el
                        },
                        on: { change: _vm.changeType }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "markets-ui-fieldset",
                    {
                      class: _vm.namespace("menu-strategy"),
                      attrs: {
                        skin: _vm.watchedSettings.skin,
                        legend: _vm.initedLabels.strategy
                      }
                    },
                    [
                      _c("markets-ui-menus", {
                        attrs: {
                          skin: _vm.watchedSettings.skin,
                          dataModel: _vm.strategiesDataModel,
                          "resize-element": _vm.$el
                        },
                        on: { change: _vm.changeStrategy }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { class: _vm.namespace("menu-expiration-strike") },
                    [
                      _c(
                        "markets-ui-fieldset",
                        {
                          class: _vm.namespace("menu-expiration"),
                          attrs: {
                            skin: _vm.watchedSettings.skin,
                            legend: _vm.initedLabels.expiration
                          }
                        },
                        [
                          _c("markets-ui-combo-box", {
                            attrs: {
                              size: "small",
                              skin: _vm.watchedSettings.skin,
                              dataModel: _vm.expirationDataModel,
                              width: 110,
                              label: _vm.initedLabels.expiration
                            },
                            on: { change: _vm.changeExpiration }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "markets-ui-fieldset",
                        {
                          class: _vm.namespace("menu-strike-range"),
                          attrs: {
                            skin: _vm.watchedSettings.skin,
                            legend: _vm.initedLabels.strikeRange
                          }
                        },
                        [
                          _c("markets-ui-select", {
                            attrs: {
                              size: "small",
                              skin: _vm.watchedSettings.skin,
                              dataModel: _vm.strikeRangesDataModel,
                              label: _vm.initedLabels.strikeRange,
                              "hidden-label": ""
                            },
                            on: { change: _vm.changeStrikeRange }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "markets-ui-fieldset",
                    {
                      class: _vm.namespace("menu-datapoint"),
                      attrs: {
                        skin: _vm.watchedSettings.skin,
                        legend: _vm.initedLabels.dataPoints
                      }
                    },
                    [
                      _c("markets-ui-button", {
                        attrs: {
                          "icon-right": "caret-down",
                          skin: _vm.watchedSettings.skin,
                          text: _vm.initedLabels.dataPoints,
                          "aria-expanded": "" + this.settingsPopoverVisible
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.toggleSettings($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.errorMessage
            ? _c(
                "div",
                {
                  ref: "grid",
                  class: _vm.namespace("grid"),
                  style: _vm.gridStyles
                },
                [
                  _vm.hasCalls
                    ? _c("markets-ui-grid", {
                        ref: "callsGrid",
                        class: [
                          _vm.namespace("grid__calls"),
                          _vm.gridOption.calls.clsName
                        ],
                        attrs: {
                          skin: _vm.watchedSettings.skin,
                          formatter: _vm.formatter,
                          option: _vm.gridOption.calls,
                          columns: _vm.gridColumns.calls,
                          rows: _vm.gridRows.calls,
                          frozenGroupHeader: "",
                          scrollTop: _vm.gridEventData.puts.scrollTop,
                          scrollLeft: _vm.gridEventData.puts.scrollLeft,
                          sortColumn: _vm.gridEventData.puts.sortColumn,
                          columnWidth: _vm.gridEventData.puts.columnWidth,
                          hoverRow: _vm.gridEventData.puts.hoverRow
                        },
                        on: {
                          scroll: function($event) {
                            return _vm.syncScroll(
                              _vm.gridOption.calls.type,
                              $event
                            )
                          },
                          sort: function($event) {
                            return _vm.syncSort(
                              _vm.gridOption.calls.type,
                              $event
                            )
                          },
                          "column-width-changed": function($event) {
                            return _vm.syncColumnWidthChanged(
                              _vm.gridOption.calls.type,
                              $event
                            )
                          },
                          "row-mouse-enter": function($event) {
                            return _vm.syncRowMouseEnter(
                              _vm.gridOption.calls.type,
                              $event
                            )
                          },
                          "row-mouse-leave": function($event) {
                            return _vm.syncRowMouseLeave(
                              _vm.gridOption.calls.type,
                              $event
                            )
                          },
                          "cell-mouse-enter": _vm.bindCellMouseEnter,
                          "cell-mouse-leave": _vm.bindCellMouseLeave,
                          "visible-rows-update": function($event) {
                            var i = arguments.length,
                              argsArray = Array(i)
                            while (i--) argsArray[i] = arguments[i]
                            return _vm.visibleRowsUpdate.apply(
                              void 0,
                              ["calls"].concat(argsArray)
                            )
                          }
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.hasPuts
                    ? _c("markets-ui-grid", {
                        ref: "putsGrid",
                        class: [
                          _vm.namespace("grid__puts"),
                          _vm.gridOption.puts.clsName
                        ],
                        attrs: {
                          skin: _vm.watchedSettings.skin,
                          formatter: _vm.formatter,
                          option: _vm.gridOption.puts,
                          columns: _vm.gridColumns.puts,
                          rows: _vm.gridRows.puts,
                          frozenGroupHeader: "",
                          scrollTop: _vm.gridEventData.calls.scrollTop,
                          scrollLeft: _vm.gridEventData.calls.scrollLeft,
                          sortColumn: _vm.gridEventData.calls.sortColumn,
                          columnWidth: _vm.gridEventData.calls.columnWidth,
                          hoverRow: _vm.gridEventData.calls.hoverRow
                        },
                        on: {
                          scroll: function($event) {
                            return _vm.syncScroll(
                              _vm.gridOption.puts.type,
                              $event
                            )
                          },
                          sort: function($event) {
                            return _vm.syncSort(
                              _vm.gridOption.puts.type,
                              $event
                            )
                          },
                          "column-width-changed": function($event) {
                            return _vm.syncColumnWidthChanged(
                              _vm.gridOption.puts.type,
                              $event
                            )
                          },
                          "row-mouse-enter": function($event) {
                            return _vm.syncRowMouseEnter(
                              _vm.gridOption.puts.type,
                              $event
                            )
                          },
                          "row-mouse-leave": function($event) {
                            return _vm.syncRowMouseLeave(
                              _vm.gridOption.puts.type,
                              $event
                            )
                          },
                          "cell-mouse-enter": _vm.bindCellMouseEnter,
                          "cell-mouse-leave": _vm.bindCellMouseLeave,
                          "visible-rows-update": function($event) {
                            var i = arguments.length,
                              argsArray = Array(i)
                            while (i--) argsArray[i] = arguments[i]
                            return _vm.visibleRowsUpdate.apply(
                              void 0,
                              ["puts"].concat(argsArray)
                            )
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "markets-ui-popover",
            {
              class: _vm.namespace("greeks-popover"),
              attrs: {
                skin: _vm.watchedSettings.skin,
                visible: _vm.greeksPopoverVisible,
                "triggered-by": _vm.greeksPopoverTriggeredId,
                "title-hidden": false,
                title: _vm.initedLabels.greekDataPoints
              }
            },
            [
              _c(
                "span",
                {
                  class: _vm.namespace("greeks__header-strike"),
                  attrs: { slot: "actions" },
                  slot: "actions"
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.greeksPopoverData.strike) +
                      "\n            "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "ul",
                { class: _vm.namespace("list-group") },
                _vm._l(_vm.greeksPopoverData.greeks, function(item, index) {
                  return _c(
                    "li",
                    { key: index, class: _vm.namespace("list-group__item") },
                    [
                      _c(
                        "span",
                        { class: _vm.namespace("list-group__item-label") },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.label) +
                              "\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { class: _vm.namespace("list-group__item-value") },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(item.value) +
                              "\n                    "
                          )
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "markets-ui-settings",
        {
          ref: "optionsUiSettings",
          class: _vm.namespace("settings"),
          attrs: {
            slot: "settings",
            showDone: "",
            skin: _vm.watchedSettings.skin,
            "triggered-by": _vm.settingsPopoverTriggeredId,
            title: _vm.settingsPopoverTitle,
            visible: _vm.settingsPopoverVisible
          },
          on: { hide: _vm.hideSettingsPopover, done: _vm.changeDataPoints },
          slot: "settings"
        },
        [
          _c(
            "section",
            {
              class: _vm.namespace("setting__datapoints"),
              attrs: { slot: "content" },
              slot: "content"
            },
            [
              _c(
                "markets-ui-form",
                [
                  _c("markets-ui-checkbox-group", {
                    attrs: {
                      size: "small",
                      skin: _vm.watchedSettings.skin,
                      dataModel: _vm.regularDataPointsDataModel
                    },
                    on: { change: _vm.changeSettingDataPoints }
                  }),
                  _vm._v(" "),
                  _c("markets-ui-radio-button-group", {
                    attrs: {
                      size: "small",
                      isHorizontal: false,
                      title: _vm.initedLabels.greekDataPoints,
                      skin: _vm.watchedSettings.skin,
                      dataModel: _vm.greekDataPointsDataModel
                    },
                    on: { change: _vm.changeGreeksView }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }