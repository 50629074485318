<template>
    <markets-ui-container
        :class="namespace()"
        :skin="watchedSettings.skin"
        :errorMessage="errorMessage"
        :showBorder="watchedSettings.showBorder"
        :showHeader="watchedSettings.showHeader"
        :showSetting="watchedSettings.showSetting"
        :showLoading="showLoading"
        @setting-click="toggleSettings"
    >
        <div ref="content" slot="content" :class="namespace('content')">
            <div ref="menu" v-if="!errorMessage" :class="namespace('menu')">
                <markets-ui-fieldset
                    :class="namespace('menu-type')"
                    :skin="watchedSettings.skin"
                    :legend="initedLabels.type"
                >
                    <markets-ui-menus
                        :skin="watchedSettings.skin"
                        :dataModel="typesDataModel"
                        :resize-element="$el"
                        @change="changeType"
                    >
                    </markets-ui-menus>
                </markets-ui-fieldset>
                <markets-ui-fieldset
                    :class="namespace('menu-strategy')"
                    :skin="watchedSettings.skin"
                    :legend="initedLabels.strategy"
                >
                    <markets-ui-menus
                        :skin="watchedSettings.skin"
                        :dataModel="strategiesDataModel"
                        :resize-element="$el"
                        @change="changeStrategy"
                    >
                    </markets-ui-menus>
                </markets-ui-fieldset>
                <div :class="namespace('menu-expiration-strike')">
                    <markets-ui-fieldset
                        :class="namespace('menu-expiration')"
                        :skin="watchedSettings.skin"
                        :legend="initedLabels.expiration"
                    >
                        <markets-ui-combo-box
                            size="small"
                            :skin="watchedSettings.skin"
                            :dataModel="expirationDataModel"
                            :width="110"
                            :label="initedLabels.expiration"
                            @change="changeExpiration"
                        />
                    </markets-ui-fieldset>
                    <markets-ui-fieldset
                        :class="namespace('menu-strike-range')"
                        :skin="watchedSettings.skin"
                        :legend="initedLabels.strikeRange"
                    >
                        <markets-ui-select
                            size="small"
                            :skin="watchedSettings.skin"
                            :dataModel="strikeRangesDataModel"
                            :label="initedLabels.strikeRange"
                            hidden-label
                            @change="changeStrikeRange"
                        ></markets-ui-select>
                    </markets-ui-fieldset>
                </div>
                <markets-ui-fieldset
                    :class="namespace('menu-datapoint')"
                    :skin="watchedSettings.skin"
                    :legend="initedLabels.dataPoints"
                >
                    <markets-ui-button
                        icon-right="caret-down"
                        :skin="watchedSettings.skin"
                        :text="initedLabels.dataPoints"
                        :aria-expanded="'' + this.settingsPopoverVisible"
                        @click.stop="toggleSettings"
                    >
                    </markets-ui-button>
                </markets-ui-fieldset>
            </div>
            <div
                ref="grid"
                v-if="!errorMessage"
                :class="namespace('grid')"
                :style="gridStyles"
            >
                <markets-ui-grid
                    v-if="hasCalls"
                    ref="callsGrid"
                    :class="[
                        namespace('grid__calls'),
                        gridOption.calls.clsName
                    ]"
                    :skin="watchedSettings.skin"
                    :formatter="formatter"
                    :option="gridOption.calls"
                    :columns="gridColumns.calls"
                    :rows="gridRows.calls"
                    frozenGroupHeader
                    :scrollTop="gridEventData.puts.scrollTop"
                    :scrollLeft="gridEventData.puts.scrollLeft"
                    :sortColumn="gridEventData.puts.sortColumn"
                    :columnWidth="gridEventData.puts.columnWidth"
                    :hoverRow="gridEventData.puts.hoverRow"
                    @scroll="syncScroll(gridOption.calls.type, $event)"
                    @sort="syncSort(gridOption.calls.type, $event)"
                    @column-width-changed="
                        syncColumnWidthChanged(gridOption.calls.type, $event)
                    "
                    @row-mouse-enter="
                        syncRowMouseEnter(gridOption.calls.type, $event)
                    "
                    @row-mouse-leave="
                        syncRowMouseLeave(gridOption.calls.type, $event)
                    "
                    @cell-mouse-enter="bindCellMouseEnter"
                    @cell-mouse-leave="bindCellMouseLeave"
                    @visible-rows-update="
                        visibleRowsUpdate('calls', ...arguments)
                    "
                >
                </markets-ui-grid>
                <markets-ui-grid
                    v-if="hasPuts"
                    ref="putsGrid"
                    :class="[namespace('grid__puts'), gridOption.puts.clsName]"
                    :skin="watchedSettings.skin"
                    :formatter="formatter"
                    :option="gridOption.puts"
                    :columns="gridColumns.puts"
                    :rows="gridRows.puts"
                    frozenGroupHeader
                    :scrollTop="gridEventData.calls.scrollTop"
                    :scrollLeft="gridEventData.calls.scrollLeft"
                    :sortColumn="gridEventData.calls.sortColumn"
                    :columnWidth="gridEventData.calls.columnWidth"
                    :hoverRow="gridEventData.calls.hoverRow"
                    @scroll="syncScroll(gridOption.puts.type, $event)"
                    @sort="syncSort(gridOption.puts.type, $event)"
                    @column-width-changed="
                        syncColumnWidthChanged(gridOption.puts.type, $event)
                    "
                    @row-mouse-enter="
                        syncRowMouseEnter(gridOption.puts.type, $event)
                    "
                    @row-mouse-leave="
                        syncRowMouseLeave(gridOption.puts.type, $event)
                    "
                    @cell-mouse-enter="bindCellMouseEnter"
                    @cell-mouse-leave="bindCellMouseLeave"
                    @visible-rows-update="
                        visibleRowsUpdate('puts', ...arguments)
                    "
                >
                </markets-ui-grid>
            </div>
            <markets-ui-popover
                :class="namespace('greeks-popover')"
                :skin="watchedSettings.skin"
                :visible="greeksPopoverVisible"
                :triggered-by="greeksPopoverTriggeredId"
                :title-hidden="false"
                :title="initedLabels.greekDataPoints"
            >
                <span
                    slot="actions"
                    :class="namespace('greeks__header-strike')"
                >
                    {{ greeksPopoverData.strike }}
                </span>
                <ul :class="namespace('list-group')">
                    <li
                        :class="namespace('list-group__item')"
                        v-for="(item, index) in greeksPopoverData.greeks"
                        :key="index"
                    >
                        <span :class="namespace('list-group__item-label')">
                            {{ item.label }}
                        </span>
                        <span :class="namespace('list-group__item-value')">
                            {{ item.value }}
                        </span>
                    </li>
                </ul>
            </markets-ui-popover>
        </div>
        <markets-ui-settings
            ref="optionsUiSettings"
            slot="settings"
            showDone
            :class="namespace('settings')"
            :skin="watchedSettings.skin"
            :triggered-by="settingsPopoverTriggeredId"
            :title="settingsPopoverTitle"
            :visible="settingsPopoverVisible"
            @hide="hideSettingsPopover"
            @done="changeDataPoints"
        >
            <section :class="namespace('setting__datapoints')" slot="content">
                <markets-ui-form>
                    <markets-ui-checkbox-group
                        size="small"
                        :skin="watchedSettings.skin"
                        :dataModel="regularDataPointsDataModel"
                        @change="changeSettingDataPoints"
                    >
                    </markets-ui-checkbox-group>
                    <markets-ui-radio-button-group
                        size="small"
                        :isHorizontal="false"
                        :title="initedLabels.greekDataPoints"
                        :skin="watchedSettings.skin"
                        :dataModel="greekDataPointsDataModel"
                        @change="changeGreeksView"
                    >
                    </markets-ui-radio-button-group>
                </markets-ui-form>
            </section>
        </markets-ui-settings>
    </markets-ui-container>
</template>

<script>
import mwcMarketsCore from 'mwc-markets-core';
import MBG from 'mbg';
import labels from './assets/labels.json';
import {
    REGULAR_DATA_POINTS,
    STRIKE_DATA_POINT,
    GREEK_DATA_POINTS,
    GREEKS_VIEW
} from './metadata/data-points';

import { STRATEGIES } from './metadata/strategies';
import { TYPES } from './metadata/types';
import { STRIKE_RANGE } from './metadata/strike-range';
import { CLASSES } from './metadata/classes';

const { utils, mixins } = mwcMarketsCore;
window.mbg = MBG;
export default {
    mixins: [mixins.componentUI],
    name: 'mwc-markets-options-ui',
    props: {
        dataModel: {
            type: Object,
            default() {
                return {
                    expiration: {},
                    data: {}
                };
            }
        }
    },
    data() {
        return {
            greeksPopoverVisible: false,
            greeksPopoverTriggeredId: null,
            greeksPopoverData: {
                strike: utils.getFormatValue({
                    formatter: this.formatter,
                    value: null,
                    dataType: 'string'
                }),
                greeks: []
            },
            settingsPopoverVisible: false,
            settingsPopoverTriggeredId: null,
            settingsPopoverTitle: '',
            gridEventData: {
                calls: {},
                puts: {}
            },
            gridWidth: Number.MAX_SAFE_INTEGER,
            gridHeight: 100,
            regularDataPointsDataModel: [],
            greekDataPointsDataModel: []
        };
    },
    computed: {
        gridStyles() {
            if (!this.watchedSettings.autoHeight) {
                return {
                    height: `${this.gridHeight}px`
                };
            }
            return {};
        },
        typesDataModel() {
            return [TYPES.ALL, TYPES.CALLS, TYPES.PUTS].map(id => {
                return {
                    id,
                    name: this.initedLabels[id],
                    selected: this.watchedSettings.type === id
                };
            });
        },
        strategiesDataModel() {
            return [
                STRATEGIES.ALL,
                STRATEGIES.IN,
                STRATEGIES.OUT,
                STRATEGIES.NEAR
            ].map(id => {
                return {
                    id,
                    name: this.initedLabels[id],
                    selected: this.watchedSettings.strategy === id
                };
            });
        },
        expirationDataModel() {
            const dataModel =
                this.dataModel.expiration.map(value => {
                    const formatValue = utils.getFormatValue({
                        formatter: this.formatter,
                        value,
                        dataType: 'date'
                    });
                    return {
                        id: value,
                        text: formatValue,
                        name: formatValue,
                        selected: value === this.watchedSettings.expirationDate
                    };
                }) || [];
            if (
                dataModel.length > 0 &&
                !utils.find(dataModel, d => d.selected)
            ) {
                dataModel[0].selected = true;
            }
            return dataModel;
        },
        dataPointsArray() {
            const dataPoints = this.watchedSettings.dataPoints;
            if (dataPoints.length) {
                return this.watchedSettings.dataPoints.split(',');
            }
            return [];
        },
        settingsPopoverNav() {
            const emptyString = utils.getFormatValue({
                formatter: this.formatter,
                value: '',
                dataType: 'string'
            });
            const expiration = this.expirationDataModel.length
                ? utils.find(this.expirationDataModel, d => d.selected).name
                : emptyString;
            return [
                {
                    id: 'dataPoints',
                    title: this.initedLabels.dataPoints,
                    name: `${this.dataPointsArray.length} ${this.initedLabels['selected']}`
                },
                {
                    id: 'expiration',
                    title: this.initedLabels.expiration,
                    name: expiration
                },
                {
                    id: 'type',
                    title: this.initedLabels.type,
                    name: this.initedLabels[this.watchedSettings.type]
                },
                {
                    id: 'strategy',
                    title: this.initedLabels.strategy,
                    name: this.initedLabels[this.watchedSettings.strategy]
                }
            ];
        },
        hasCalls() {
            return /calls/i.test(this.watchedSettings.type);
        },
        hasPuts() {
            return /puts/i.test(this.watchedSettings.type);
        },
        gridOption() {
            const visibleRowsUpdateWaitTime = 500;
            const formatter = {
                viewFormat: value => {
                    return `<span class="${this.namespace(
                        CLASSES.GREEK_CELL_CONTENT
                    )}">${value}</span>`;
                }
            };
            const option = {
                calls: {
                    layout: 'mbg',
                    visibleRowsUpdateWaitTime,
                    type: TYPES.CALLS,
                    formatter
                },
                puts: {
                    layout: 'mbg',
                    visibleRowsUpdateWaitTime,
                    type: TYPES.PUTS,
                    formatter
                }
            };
            ['scrollbarSize', 'rowHeight', 'autoHeight'].forEach(key => {
                if (typeof this.watchedSettings[key] !== 'undefined') {
                    option.calls[key] = this.watchedSettings[key];
                    option.puts[key] = this.watchedSettings[key];
                }
            });
            if (this.hasCalls && this.hasPuts) {
                option.calls.clsName = this.namespace(
                    CLASSES.GRID_MINUS_STRIKE
                );
                option.calls.scrollbarSizeV = 0;
                option.calls.frozenColumn = -1;
                option.puts.clsName = this.namespace(CLASSES.GRID_ADD_STRIKE);
                option.puts.frozenColumn = 0;
            } else if (this.hasCalls) {
                option.puts.clsName = '';
                option.calls.clsName = this.namespace('grid__full-width');
                option.calls.frozenColumn = 0;
            } else if (this.hasPuts) {
                option.calls.clsName = '';
                option.puts.clsName = this.namespace('grid__full-width');
                option.puts.frozenColumn = 0;
            }
            return option;
        },
        regularDataPoints() {
            if (this.watchedSettings.customColumns.length > 0) {
                return this.watchedSettings.customColumns.map(item => {
                    const targetItem =
                        utils.find(
                            REGULAR_DATA_POINTS,
                            d => d.id === item.id
                        ) || {};
                    return utils.extend(
                        true,
                        {
                            id: item.id,
                            name:
                                item.label || this.initedLabels[targetItem.id],
                            visible: true,
                            selected:
                                utils.inArray(this.dataPointsArray, item.id) >
                                -1
                        },
                        targetItem,
                        item
                    );
                });
            }
            return REGULAR_DATA_POINTS.map(item => {
                return utils.extend(
                    {
                        name: this.initedLabels[item.id],
                        visible: true,
                        selected:
                            utils.inArray(this.dataPointsArray, item.id) > -1
                    },
                    item
                );
            });
        },
        regularDataPointsModel() {
            return this.regularDataPoints.filter(item => !item.isHide);
        },
        gridColumns() {
            let baseColumn = [];
            this.regularDataPoints.forEach(item => {
                if (utils.inArray(this.dataPointsArray, item.id) > -1) {
                    baseColumn.push(item);
                } else if (item.id === 'greeks') {
                    item.cellClass = this.namespace(CLASSES.GREEK_CELL);
                    baseColumn.push(item);
                }
            });
            if (this.watchedSettings.greeksView !== GREEKS_VIEW.COMBINED_VIEW) {
                const targetIndex = utils.findIndex(
                    baseColumn,
                    item => item.id === 'greeks'
                );
                if (targetIndex > -1) {
                    baseColumn.splice(targetIndex, 1);
                }
                if (this.watchedSettings.greeksView === GREEKS_VIEW.SHOW_ALL) {
                    const greeksColumns = GREEK_DATA_POINTS.map(item => {
                        return utils.extend(
                            true,
                            {
                                name: this.initedLabels[item.id]
                            },
                            item
                        );
                    });
                    // when greeksView is showAll, replace greeks to greeksColumns
                    if (targetIndex > -1) {
                        baseColumn = [
                            ...baseColumn.slice(0, targetIndex),
                            ...greeksColumns,
                            ...baseColumn.slice(targetIndex, baseColumn.length)
                        ];
                    }
                }
            }

            const strikeColumn = utils.extend(
                true,
                {
                    name: this.initedLabels.strike,
                    headerClass: this.namespace(CLASSES.STRIKE_HEADER),
                    cellClass: this.namespace(CLASSES.STRIKE_CELL)
                },
                STRIKE_DATA_POINT
            );
            if (this.hasCalls && this.hasPuts) {
                return {
                    calls: this.composeColumns(TYPES.CALLS, baseColumn),
                    puts: [
                        strikeColumn,
                        ...this.composeColumns(TYPES.PUTS, baseColumn)
                    ]
                };
            } else if (this.hasCalls) {
                return {
                    calls: [
                        strikeColumn,
                        ...this.composeColumns(TYPES.CALLS, baseColumn)
                    ]
                };
            } else if (this.hasPuts) {
                return {
                    puts: [
                        strikeColumn,
                        ...this.composeColumns(TYPES.PUTS, baseColumn)
                    ]
                };
            } else {
                return {};
            }
        },
        strikeAllList() {
            const { puts, calls } = this.dataModel.data;
            if (puts && calls) {
                const rows = [
                    ...calls.map(r => r.strike),
                    ...puts.map(r => r.strike)
                ];
                return Array.from(new Set(rows));
            }
            return [];
        },
        strategyFilteredRows() {
            const strikeList = this.calculateAtTheMoney(this.dataModel.data);
            const calls = [],
                puts = [];
            const validRows = (type, item) => {
                const row = this.filterRowByStrategy(type, item, strikeList);
                if (row) {
                    if (!row.isEmpty) {
                        row.greeks = this.initedLabels.view;
                    }
                    if (type === TYPES.CALLS) {
                        calls.push(row);
                    } else {
                        puts.push(row);
                    }
                }
            };
            if (this.hasValidCalls()) {
                this.dataModel.data.calls.forEach(item =>
                    validRows(TYPES.CALLS, item)
                );
            }
            if (this.hasValidPuts()) {
                this.dataModel.data.puts.forEach(item =>
                    validRows(TYPES.PUTS, item)
                );
            }
            if (
                this.watchedSettings.type === TYPES.ALL &&
                this.watchedSettings.strategy === STRATEGIES.ALL
            ) {
                const sortFn = function(a, b) {
                    const price1 = parseFloat(a.strike, 10),
                        price2 = parseFloat(b.strike, 10);
                    if (price1 > price2) {
                        return 1;
                    } else if (price1 < price2) {
                        return -1;
                    } else {
                        return a.id.length - b.id.length;
                    }
                };
                this.strikeAllList.forEach(strike => {
                    const callTarget = utils.find(
                        calls,
                        cItem => cItem.strike === strike
                    );
                    const putTarget = utils.find(
                        puts,
                        pItem => pItem.strike === strike
                    );
                    if (!callTarget) {
                        calls.push({
                            strike: strike,
                            type: 'calls',
                            id: putTarget.id
                        });
                    }
                    if (!putTarget) {
                        puts.push({
                            strike: strike,
                            type: 'puts',
                            id: callTarget.id
                        });
                    }
                });
                calls.sort(sortFn);
                puts.sort(sortFn);
            }

            return {
                calls,
                puts
            };
        },
        strikeRangesDataModel() {
            const { calls, puts } = this.strategyFilteredRows;
            const length = calls.length || puts.length;
            const ranges = [];
            if (length) {
                Object.values(STRIKE_RANGE).forEach(item => {
                    if ((!isNaN(item) && item <= length) || isNaN(item)) {
                        const allNearTheMoney =
                            this.watchedSettings.strategy === STRATEGIES.NEAR &&
                            item === STRIKE_RANGE.ALL;
                        ranges.push({
                            id: `${item}`,
                            name: this.initedLabels[item] || item,
                            selected:
                                item === this.watchedSettings.strikeRange ||
                                allNearTheMoney
                        });
                    }
                });
            }
            return ranges;
        },
        gridRows() {
            let { calls, puts } = this.strategyFilteredRows;
            if (this.watchedSettings.strikeRange !== STRIKE_RANGE.ALL) {
                const hasPutsAndCalls = () => {
                    calls = this.getRowsByStrikeRange(
                        calls,
                        this.watchedSettings.strikeRange
                    );
                    puts = this.getRowsByStrikeRange(
                        puts,
                        this.watchedSettings.strikeRange
                    );
                };
                if (this.watchedSettings.strategy === STRATEGIES.IN) {
                    if (this.watchedSettings.type === TYPES.CALLS) {
                        calls = calls.slice(-this.watchedSettings.strikeRange);
                    } else if (this.watchedSettings.type === TYPES.PUTS) {
                        puts = puts.slice(0, this.watchedSettings.strikeRange);
                    } else {
                        hasPutsAndCalls();
                    }
                } else if (this.watchedSettings.strategy === STRATEGIES.OUT) {
                    if (this.watchedSettings.type === TYPES.CALLS) {
                        calls = calls.slice(
                            0,
                            this.watchedSettings.strikeRange
                        );
                    } else if (this.watchedSettings.type === TYPES.PUTS) {
                        puts = puts.slice(-this.watchedSettings.strikeRange);
                    } else {
                        hasPutsAndCalls();
                    }
                } else {
                    hasPutsAndCalls();
                }
            }
            return { calls, puts };
        }
    },
    created() {
        this.initedLabels = this.mergeLabels(
            this.labels,
            this.getDefaultLabels(labels, this.watchedSettings.languageId)
        );
        this.namespace = utils.namespace('options');
        if (this.watchedSettings.strategy === 'atTheMoney') {
            this.watchedSettings.strategy = STRATEGIES.NEAR;
        }
        const dataPoints = this.watchedSettings.dataPoints;
        if (utils.isArray(dataPoints)) {
            this.watchedSettings.dataPoints = dataPoints.join();
        }
        this.settingsPopoverTitle = this.initedLabels.dataPoints;
        this.settingDataPointsSelected = {
            regular: null,
            greeksView: null
        };
        this.fireRowsUpdateTimeout = null;
        this.visibleRowsUpdateQueue = {
            calls: null,
            puts: null
        };
        this.moduleClassName = utils.moduleClassName;
        this.regularDataPointsDataModel = this.regularDataPointsModel;
        this.greekDataPointsDataModel = this.getGreekDataPoints();
    },
    mounted() {
        const contentSize = utils.innerSizes(this.$refs.content);
        this.gridWidth = contentSize.width;

        this.gridHeight = this.calculateGridHeight(contentSize.height);
    },
    watch: {
        'dataModel.security.queryKey': function() {
            this.changeSettings(this._getStoreSettings());
        },
        'dataModel.expiration': function(value) {
            if (
                utils.inArray(value, this.watchedSettings.expirationDate) === -1
            ) {
                this.watchedSettings.expirationDate = value[0];
            }
            this.changeSettings(this._getStoreSettings());
        },
        'watchedSettings.greeksView': function(value) {
            if (value) {
                // Tracking: number of usage on each view (show all, combine, hide all).
                this.trackEvent({
                    name: 'greeks-view-change',
                    value
                });
            }
            this.$emit('greeks-view-changed', value);
        },
        strategyFilteredRows: {
            handler: function(value) {
                const { calls = [], puts = [] } = value;
                const length = calls.length || puts.length;
                if (
                    length &&
                    this.watchedSettings.strategy !== STRATEGIES.NEAR &&
                    !isNaN(this.watchedSettings.strikeRange) &&
                    this.watchedSettings.strikeRange > length
                ) {
                    this.watchedSettings.strikeRange = STRIKE_RANGE.ALL;
                }
            },
            deep: true
        }
    },
    methods: {
        _resizeObserverCallback({ entry }) {
            this.hideSettingsPopover();
            this.gridWidth = entry.contentRect.width;
            this.$nextTick(() => {
                this.gridHeight = this.calculateGridHeight();
            });
        },
        getGreekDataPoints() {
            return [
                GREEKS_VIEW.SHOW_ALL,
                GREEKS_VIEW.COMBINED_VIEW,
                GREEKS_VIEW.HIDE_ALL
            ].map(id => {
                return {
                    id,
                    name: this.initedLabels[id],
                    selected: this.watchedSettings.greeksView === id
                };
            });
        },
        calculateMenuHeight() {
            return utils.outerSizes(this.$refs.menu, true).height;
        },
        calculateGridHeight(elHeight) {
            elHeight = elHeight
                ? elHeight
                : utils.innerSizes(this.$refs.content).height;
            const gridPaddings = utils.getPaddings(this.$refs.grid);
            return (
                elHeight -
                this.calculateMenuHeight() -
                gridPaddings.top -
                gridPaddings.bottom
            );
        },
        emptyRow(type, row) {
            const column = utils.find(
                this.gridColumns[type],
                column => column.subs && column.subs.length
            );
            if (column) {
                column.subs.forEach(column => {
                    if (column.id !== 'strike') {
                        row[column.id] = '';
                    }
                });
                row.isEmpty = true;
            }
        },

        filterRowByStrategy(type, row, strikeList) {
            let cloneRow = {
                id: row.id,
                strike: row.strike,
                localTimeZone: this.dataModel.security.timezoneAbbreviation,
                type
            };
            const column = utils.find(
                this.gridColumns[type],
                column => column.subs && column.subs.length
            );
            if (column) {
                column.subs.forEach(col => {
                    cloneRow[col.id] = row[col.id];
                });
            }
            const lessLastPrice =
                cloneRow.strike < this.dataModel.data.root.lastPrice;
            const isALL = this.watchedSettings.type === TYPES.ALL;
            const emptyRow = () => {
                if (isALL) {
                    this.emptyRow(type, cloneRow);
                } else {
                    cloneRow = null;
                }
            };
            switch (this.watchedSettings.strategy) {
                case STRATEGIES.IN:
                    if (type === TYPES.CALLS) {
                        if (lessLastPrice) {
                            cloneRow.rowClass = this.namespace(
                                CLASSES.GRID_ROWS_IN
                            );
                        } else {
                            emptyRow();
                        }
                    } else {
                        if (lessLastPrice) {
                            emptyRow();
                        } else {
                            cloneRow.rowClass = this.namespace(
                                CLASSES.GRID_ROWS_IN
                            );
                        }
                    }
                    break;
                case STRATEGIES.OUT:
                    if (type === TYPES.CALLS) {
                        if (lessLastPrice) {
                            emptyRow();
                        }
                    } else if (!lessLastPrice) {
                        emptyRow();
                    }
                    break;
                case STRATEGIES.NEAR:
                    if (utils.inArray(strikeList, cloneRow.strike) >= 0) {
                        cloneRow.rowClass = this.namespace(
                            CLASSES.GRID_ROWS_IN
                        );
                    } else {
                        cloneRow = null;
                    }
                    break;
                default:
                    if (type === TYPES.CALLS) {
                        if (lessLastPrice) {
                            cloneRow.rowClass = this.namespace(
                                CLASSES.GRID_ROWS_IN
                            );
                        }
                    } else {
                        if (!lessLastPrice) {
                            cloneRow.rowClass = this.namespace(
                                CLASSES.GRID_ROWS_IN
                            );
                        }
                    }
                    break;
            }
            return cloneRow;
        },
        getRowsByStrikeRange(rows, range) {
            const _rows = [];
            rows.forEach(item => {
                const strikePrice = utils.isObject(item) ? item.strike : item;
                if (
                    strikePrice - this.dataModel.data.root.lastPrice <= 0 &&
                    _rows.length <= range / 2
                ) {
                    if (_rows.length === range / 2) {
                        _rows.shift();
                    }
                    _rows.push(item);
                } else {
                    if (_rows.length < range) {
                        _rows.push(item);
                    }
                }
            });
            return _rows;
        },
        calculateAtTheMoney(data) {
            const { calls, puts } = data;
            let rows = [];
            if (this.hasValidCalls() && this.hasValidPuts()) {
                rows = this.strikeAllList;
            } else if (this.hasValidCalls()) {
                rows = calls.map(r => r.strike);
            } else if (this.hasValidPuts()) {
                rows = puts.map(r => r.strike);
            }
            return this.getRowsByStrikeRange(rows, 2);
        },
        hasValidCalls() {
            return (
                this.hasCalls &&
                this.dataModel.data.root &&
                this.dataModel.data.calls
            );
        },
        hasValidPuts() {
            return (
                this.hasPuts &&
                this.dataModel.data.root &&
                this.dataModel.data.puts
            );
        },
        composeColumns(type, subs) {
            return [
                utils.extend(
                    true,
                    {},
                    {
                        id: `${type}`,
                        name: this.initedLabels[type],
                        subs: subs
                    }
                )
            ];
        },
        syncScroll(type, { scrollTop, scrollLeft }) {
            this.$set(this.gridEventData[type], 'scrollTop', scrollTop);
            this.$set(this.gridEventData[type], 'scrollLeft', scrollLeft);
        },
        syncSort(type, { column }) {
            this.$set(this.gridEventData[type], 'sortColumn', {
                id: column.id,
                order: column.sortAsc ? 'ascending' : 'descending',
                clearStatus: true
            });
        },
        syncColumnWidthChanged(type, { column }) {
            this.$set(this.gridEventData[type], 'columnWidth', {
                id: column.id,
                width: column.width
            });
        },
        syncRowMouseEnter(type, { row }) {
            this.$set(this.gridEventData[type], 'hoverRow', {
                index: row.tg_index,
                hover: true
            });
        },
        syncRowMouseLeave(type, { row }) {
            this.$set(this.gridEventData[type], 'hoverRow', {
                index: row.tg_index,
                hover: false
            });
        },

        bindCellMouseEnter(d) {
            const rowItem = d.row;
            const target = d.e.target;
            if (rowItem.greeks && this._isGreekCell(target)) {
                this.greeksPopoverData = {
                    strike: `(${rowItem.strike})`,
                    greeks: GREEK_DATA_POINTS.map(item => {
                        const row =
                            utils.find(
                                this.dataModel.data[rowItem.type],
                                item => rowItem.id === item.id
                            ) || {};
                        return {
                            label: this.initedLabels[item.id],
                            value: utils.getFormatValue({
                                formatter: this.formatter,
                                value: row[item.id],
                                dataType: 'number'
                            })
                        };
                    })
                };
                this.greeksPopoverTriggeredId = utils.computeTriggerId(
                    this._getViewSpan(target)
                );
                this.greeksPopoverVisible = true;
            } else {
                this.greeksPopoverVisible = false;
            }
        },
        bindCellMouseLeave(d) {
            this.greeksPopoverVisible = false;
        },
        visibleRowsUpdate(type, data) {
            this.visibleRowsUpdateQueue[type] = data.visibleRows.map(
                row => row.id
            );
            this.fireRowsUpdateEvent();
        },
        fireRowsUpdateEvent() {
            this.fireRowsUpdateTimeout = null;
            window.clearTimeout(this.fireRowsUpdateTimeout);
            const callRows = this.visibleRowsUpdateQueue.calls;
            const putRows = this.visibleRowsUpdateQueue.puts;
            let wait = false;
            if (this.hasCalls && !callRows) {
                wait = true;
            }
            if (this.hasPuts && !putRows) {
                wait = true;
            }
            if (wait) {
                this.fireRowsUpdateTimeout = window.setTimeout(() => {
                    this.fireRowsUpdateEvent();
                }, 100);
            } else {
                const rows = [];
                if (callRows) {
                    callRows.forEach(instrument =>
                        rows.push({
                            type: TYPES.CALLS,
                            instrument
                        })
                    );
                }
                if (putRows) {
                    putRows.forEach(instrument =>
                        rows.push({
                            type: TYPES.PUTS,
                            instrument
                        })
                    );
                }
                this.$emit('visible-rows-update', rows);
                this.visibleRowsUpdateQueue.calls = null;
                this.visibleRowsUpdateQueue.puts = null;
            }
        },
        toggleSettings(event) {
            this.settingsPopoverVisible = !this.settingsPopoverVisible;
            this.settingsPopoverTriggeredId = utils.computeTriggerId(
                event.currentTarget
            );
            if (this.settingsPopoverVisible) {
                this.regularDataPointsDataModel = this.regularDataPointsModel;
                this.greekDataPointsDataModel = this.getGreekDataPoints();
            }
        },
        changeType(item) {
            this.watchedSettings.type = item.id;
            this.changeSettings(this._getStoreSettings());
        },
        changeStrategy(item) {
            this.watchedSettings.strategy = item.id;
            this.changeSettings(this._getStoreSettings());
        },
        changeExpiration(item) {
            if (item) {
                this.watchedSettings.expirationDate =
                    typeof item === 'string' ? item : item.id;
                this.$emit(
                    'expiration-date-changed',
                    this.watchedSettings.expirationDate
                );
                this.changeSettings(this._getStoreSettings());
            }
        },
        changeStrikeRange(item) {
            if (item) {
                this.watchedSettings.strikeRange =
                    item.id === STRIKE_RANGE.ALL ? item.id : +item.id;
                this.changeSettings(this._getStoreSettings());
            }
        },
        hideSettingsPopover() {
            this.settingsPopoverVisible = false;
            this.$emit('settings-popover-hide');
        },
        changeDataPoints() {
            const regularObj = this.settingDataPointsSelected.regular;
            if (regularObj) {
                const dataPoints = Object.keys(regularObj)
                    .filter(item => {
                        return regularObj[item];
                    })
                    .join();
                this.watchedSettings.dataPoints = dataPoints;
            }
            if (this.settingDataPointsSelected.greeksView) {
                this.watchedSettings.greeksView = this.settingDataPointsSelected.greeksView;
            }
            this.hideSettingsPopover();
            this.changeSettings(this._getStoreSettings());
        },
        changeSettingDataPoints(items) {
            this.settingDataPointsSelected.regular = items;
        },
        changeGreeksView(item) {
            this.settingDataPointsSelected.greeksView = item.id;
        },
        _getStoreSettings() {
            const symbol = utils.isEmptyObject(this.dataModel.security)
                ? this.watchedSettings.symbol
                : this.getSavedSymbol(this.dataModel.security);
            return {
                symbol,
                dataPoints: this.watchedSettings.dataPoints,
                type: this.watchedSettings.type,
                strategy: this.watchedSettings.strategy,
                expirationDate: this.watchedSettings.expirationDate,
                greeksView: this.watchedSettings.greeksView,
                strikeRange: this.watchedSettings.strikeRange
            };
        },
        _isGreekCell(target) {
            return (
                target.classList.contains(this.namespace(CLASSES.GREEK_CELL)) ||
                target.classList.contains(
                    this.namespace(CLASSES.GREEK_CELL_CONTENT)
                )
            );
        },
        _getViewSpan(target) {
            if (
                target.classList.contains(
                    this.namespace(CLASSES.GREEK_CELL_CONTENT)
                )
            ) {
                return target;
            } else {
                return target.querySelector(
                    `.${this.namespace(CLASSES.GREEK_CELL_CONTENT)}`
                );
            }
        }
    }
};
</script>

<style lang="scss">
@import '@mds/constants';
@import '@mds/typography';

$namespace: 'mwc-markets-options';
.#{$namespace} {
    &-content {
        height: 100%;
    }

    &-menu {
        display: flex;
        flex-wrap: wrap;
        padding: $mds-space-three-quarter-x 0;
        font-size: $mds-typography-font-size-s;
        &-type,
        &-strategy,
        &-expiration,
        &-strike-range,
        &-datapoint {
            padding: $mds-space-three-quarter-x;
        }
        &-expiration-strike {
            display: flex;
        }
        &-datapoint {
            .mds-fieldset__legend___markets {
                visibility: hidden;
            }
        }
        &-expiration {
            min-width: 120px;
        }
        &-strike-range {
            padding-left: $mds-space-1-x;
        }
    }

    &-grid {
        display: flex;

        &__half-width-minus-strike {
            width: calc((100% - 86px) * 0.5);
        }

        &__half-width-add-strike {
            width: calc((100% - 86px) * 0.5 + 86px);
        }

        &__calls,
        &__puts {
            .tg-pane-header {
                .tg-pane-header-right,
                .tg-pane-top-right {
                    padding-left: $mds-space-1-x;
                }
            }

            .tg-pane-bodyer {
                .tg-scrollpane {
                    .tg-scrollbody {
                        transform: translateZ(0);

                        .tg-list-first {
                            padding-left: 9px;
                        }
                    }
                }

                .tg-pane-right {
                    .tg-scrollbody {
                        padding-left: $mds-space-half-x;
                    }
                }
            }
        }

        &__full-width {
            width: 100%;
        }

        &__strike-header {
            .tg-column-name {
                font-weight: $mds-typography-font-weight-bold;
                font-size: $mds-typography-font-size-s;
                color: $mds-color-teal-19;
            }
        }

        & .tg-mbg {
            .#{$namespace}-grid {
                &__greeks-cell {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }

            .tg-cell {
                border-right: none;
            }

            .tg-scrollview {
                height: 100% !important;
            }

            .tg-row.tg-hover {
                background: $mds-color-neutral-90;
            }
        }
    }

    &-grid__row-in {
        border-top-color: $mds-color-neutral-80;

        .tg-cell {
            background: $mds-color-neutral-90;
            opacity: 0.8;
            color: $mds-color-black;
        }
    }

    & .tg-mbg &-grid__row-in.tg-hover {
        background: $mds-color-neutral-67;
    }

    & .tg-mbg .tg-row {
        .#{$namespace}-grid__strike-cell {
            font-weight: $mds-typography-font-weight-bold;
            color: $mds-color-white;
            background: $mds-color-teal-19;
            opacity: 0.8;
            font-size: $mds-typography-font-size-s;
        }
    }

    &-greeks {
        &-popover {
            .#{$namespace}-list-group__item {
                // fix dev site issue
                font-size: $mds-typography-font-size-s;
                padding: 0;
                display: flex;
                justify-content: space-between;
                border-top: 0;
            }

            .#{$namespace}-list-group {
                &__item-value,
                &__item-label {
                    font-weight: $mds-typography-font-weight-regular;
                }
            }
            ul,
            li {
                padding: 0;
                margin: 0;
            }
        }

        &__header-strike {
            @include mds-body-text-s();
        }
    }

    &-greeks__header {
        &-title > h3 {
            font-weight: 700;
        }

        &-strike {
            margin-block-start: $mds-space-2-x;
            margin-block-end: $mds-space-2-x;
            font-size: $mds-typography-font-size-m;
        }

        &-title,
        &-strike {
            display: inline-flex;
        }

        padding-bottom: 0;
        font-weight: $mds-typography-font-weight-light;
    }

    &-settings {
        .markets-ui-radio-button-group {
            margin: $mds-space-1-and-a-half-x 0;
        }
        legend {
            padding-bottom: $mds-space-1-x;
            border-bottom: solid 2px $mds-color-neutral-20;
            width: 100%;
        }
        .markets-ui-settings__content {
            max-height: 330px;
        }
        .mds-fieldset--radio-group___markets.markets-ui-radio-button-group {
            margin-bottom: 0px;
        }
    }

    &.markets-ui-container__dark-gray {
        background: $mds-background-color-dark-gray;
        color: $mds-text-color-primary-on-dark;

        .tg-row.tg-hover {
            background: $mds-color-neutral-20;
        }
    }

    &.markets-ui-container__dark-gray .tg-mbg &-grid__row-in {
        border-top-color: $mds-color-neutral-20;

        .tg-cell {
            background: $mds-color-neutral-20;
            color: $mds-text-color-primary-on-dark;
        }
    }

    &.markets-ui-container__dark-gray .tg-mbg &-grid__row-in.tg-hover {
        background: $mds-color-neutral-50;
    }

    &.markets-ui-container__dark-gray .tg-mbg .tg-row {
        .#{$namespace}-grid__strike-cell {
            color: $mds-color-white;
            background: $mds-color-teal-19;
        }

        .markets-ui-grid {
            &-flash__negative {
                background: $mds-text-color-performance-negative-on-dark;
            }

            &-flash__positive {
                background: $mds-text-color-performance-positive-on-dark;
            }
        }
    }

    &.markets-ui-container__dark-gray .tg-mbg .tg-column-group-name {
        color: $mds-text-color-primary-on-dark;
    }

    .markets-ui-grid {
        &-flash__negative {
            background: $mds-visualization-color-performance-negative;
        }

        &-flash__positive {
            background: $mds-visualization-color-performance-positive;
        }
    }
}
</style>
