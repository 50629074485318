(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("mwc-markets-core"), require("mbg"));
	else if(typeof define === 'function' && define.amd)
		define(["mwc-markets-core", "mbg"], factory);
	else if(typeof exports === 'object')
		exports["mwcMarketsOptions"] = factory(require("mwc-markets-core"), require("mbg"));
	else
		root["mwcMarketsOptions"] = factory(root["mwcMarketsCore"], root["mbg"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__102__) {
return 