export const REGULAR_DATA_POINTS = [
    {
        id: 'symbol',
        width: 120,
        dataType: 'string'
    },
    {
        id: 'lastPrice',
        align: 'right',
        dataType: 'number',
        flash: 'color'
    },
    {
        id: 'chg',
        align: 'right',
        dataType: 'upDown',
        flash: 'color'
    },
    {
        id: 'bidPrice',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'askPrice',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'volume',
        align: 'right',
        dataType: 'int'
    },
    {
        id: 'openInterest',
        align: 'right',
        dataType: 'int'
    },
    {
        id: 'greeks',
        align: 'left',
        formatter: 'viewFormat',
        isHide: true
    },
    {
        id: 'tradeDateTime',
        width: 140,
        dataType: 'dateTime'
    }
];

export const STRIKE_DATA_POINT = {
    id: 'strike',
    align: 'center',
    sortable: false,
    width: 86
};

export const GREEK_DATA_POINTS = [
    {
        id: 'delta',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'gamma',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'rho',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'theta',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'vega',
        align: 'right',
        dataType: 'number'
    },
    {
        id: 'iVol',
        align: 'left',
        dataType: 'number'
    }
];

export const GREEKS_VIEW = {
    SHOW_ALL: 'showAll',
    COMBINED_VIEW: 'combinedView',
    HIDE_ALL: 'hideAll'
};
