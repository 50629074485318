<template>
    <OptionsUI
        ref="UI"
        :skin="skin"
        :dataModel="dataModel"
        :showLoading="showLoading"
        :errorCode="error.code"
        :settings="settings"
        :labels="initedLabels"
        :formatter="formatter"
        @track="trackEvent"
        @expiration-date-changed="changeExpiration"
        @settings-changed="changeSettings"
        @settings-popover-hide="hideSettingsPopover"
        @visible-rows-update="visibleRowsUpdate"
        @greeks-view-changed="changeGreeksView"
    >
    </OptionsUI>
</template>
<script>
import mwcMarketsCore from 'mwc-markets-core';
import labels from './assets/labels.json';
import OptionsUI from './options-ui';
import {
    REGULAR_DATA_POINTS,
    GREEK_DATA_POINTS,
    GREEKS_VIEW
} from './metadata/data-points';
import { STRATEGIES } from './metadata/strategies';
import { TYPES } from './metadata/types';
const { utils, mixins } = mwcMarketsCore;
// This filed of data represent is there a trade today.
const HAS_TRADE_TODAY = 'hasTradeToday';
const NO_TRADE = '0';
const CHG = 'chg';
export default {
    name: 'mwc-markets-options',
    mixins: [mixins.MwcVueHelperMixin, mixins.component],
    props: {
        symbol: {
            dataType: String
        }
    },
    data() {
        return {
            defaultConfig: {
                settings: {
                    autoHeight: false,
                    showHeader: false,
                    showSetting: true,
                    showBorder: false,
                    languageId: 'en-US',
                    skin: '',
                    dataType: '',
                    updateLastAccessTime: true,
                    type: TYPES.ALL,
                    strategy: STRATEGIES.ALL,
                    expirationDate: '',
                    strikeRange: 24,
                    dataPoints:
                        'symbol,tradeDateTime,lastPrice,chg,bidPrice,askPrice,volume,openInterest',
                    customColumns: [], //control custom columns, this is override
                    /** 
                     *  [
                            {
                                id: 'symbol',
                                width: 120,
                                dataType: 'string'
                            },
                            {
                                id: 'lastPrice',
                                align: 'right',
                                dataType: 'number',
                                flash: 'color'
                            }
                        ]
                    */
                    greeksView: GREEKS_VIEW.COMBINED_VIEW // showAll, combinedView, hideAll
                },
                labels,
                format: {
                    number: {
                        dataType: 'number',
                        maximumFractionDigits: 4,
                        minimumFractionDigits: 4
                    },
                    date: {
                        dataType: 'date',
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                    },
                    dateTime: {
                        dataType: 'date',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        day: '2-digit',
                        month: 'numeric',
                        year: 'numeric',
                        hour12: false
                    }
                },
                intlNamespace: 'mwc-markets-options'
            },
            expireDate: '',
            dataModel: {
                security: {},
                expiration: [],
                data: {}
            },
            showLoading: false
        };
    },
    computed: {
        initedLabels() {
            return this.mergeLabels(labels);
        }
    },
    watch: {
        symbol: function(value) {
            if (value && typeof value === 'string') {
                this.expireDate = null;
                this.changeSymbol(value);
                // Tracking: change symbol.
                this.trackEvent({
                    name: 'symbol-change',
                    value
                });
            }
        }
    },
    created() {
        this.chainData = null;
        this.rootSecurity = null;
        this.optionSecurities = {};
        this.currentDataType = null;
        this.pullSubscribePromise = null;
        this.streamSubscribePromise = null;
        this.greeksDataPolling = null;
        this.initialized = false;
        this.initializedSymbol = null;
        this.targetVisibility = false;
        this.changeDataType(this.settings.dataType);
        this.changeSymbol(this.settings.symbol);
        // Tracking: change symbol.
        this.trackEvent({
            name: 'symbol-change',
            value: this.settings.symbol
        });
        this.changeExpiration(this.settings.expirationDate);
    },
    mounted() {
        utils.visibilityObserver.observe(
            this.$el,
            this._visibilityObserverCallback
        );
        if (!utils.isHidden(this.$el) && !this.initialized) {
            this.initialize();
        }
    },
    beforeDestroy() {
        utils.visibilityObserver.unobserve(
            this.$el,
            this._visibilityObserverCallback
        );
        if (this.isSubscribing) {
            this._unsubscribe(
                this.currentDataType,
                this._getAllSubscribeList()
            );
            this.isSubscribing = false;
        }
        this._stopGreeksDataPolling();
    },
    methods: {
        initialize() {
            this.initialized = true;
            if (this.initializedSymbol) {
                this.changeSymbol(this.initializedSymbol);
            }
        },
        _visibilityObserverCallback({ visibility }) {
            if (visibility) {
                if (!this.initialized) {
                    this.initialize();
                } else {
                    this._subscribe(this._getAllSubscribeList());
                    this._startGreeksDataPolling();
                }
            } else {
                if (!this.targetVisibility) {
                    if (this.isSubscribing) {
                        this._unsubscribe(
                            this.currentDataType,
                            this._getAllSubscribeList()
                        );
                        this.isSubscribing = false;
                    }

                    this._stopGreeksDataPolling();
                }
                this.targetVisibility = false;
            }
        },
        changeTargetElement() {
            this.targetVisibility = true;
        },
        _getAllSubscribeList() {
            const list = this._getOptionSubscribeList();
            const root = this._getSecurityQueryKey(this.rootSecurity);
            if (root) {
                list.unshift(root);
            }
            return list;
        },
        _getOptionSubscribeList() {
            return this.optionSecurities
                ? Object.keys(this.optionSecurities)
                : [];
        },
        changeDataType(value) {
            if (!this.mktdata) {
                return;
            }
            const oldDataType = this.currentDataType;
            const subscribeList = this._getAllSubscribeList();
            this._unsubscribe(oldDataType, subscribeList);
            this.currentDataType = value === 'stream' ? value : 'pull';
            if (!this[`${this.currentDataType}SubscribePromise`]) {
                this[`${this.currentDataType}SubscribePromise`] = this.mktdata[
                    this.currentDataType
                ]();
            }
            this._subscribe(subscribeList);
        },
        _getSecurityQueryKey(security) {
            return security ? security.queryKey : null;
        },
        changeSymbol(symbol) {
            if (!this.mktdata) {
                return;
            }
            this.initializedSymbol = symbol;
            if (this.initialized) {
                this.showLoading = true;
                this.acceptTicker(symbol);
                this.mktdata
                    .securities([symbol])
                    .then(securities => {
                        const security = securities[0];
                        const currentRoot = this._getSecurityQueryKey(security);
                        const oldRoot = this._getSecurityQueryKey(
                            this.rootSecurity
                        );
                        if (
                            security.quoteInstrument &&
                            currentRoot &&
                            currentRoot !== oldRoot
                        ) {
                            this.error = {};
                            this._unsubscribe(
                                this.currentDataType,
                                this._getAllSubscribeList()
                            );
                            this.rootSecurity = security;
                            this._subscribe([currentRoot]);
                            this._getOptionChain();
                        } else {
                            this._setError();
                        }
                    })
                    .catch(e => {
                        if (e.code === -2) {
                            this._setError();
                        }
                        this.showLoading = false;
                    });
            }
        },
        _getOptionChain() {
            this.mktdata
                .optionChain({
                    security: this._getSecurityQueryKey(this.rootSecurity)
                })
                .then(data => {
                    if (!data.chain || data.chain.length === 0) {
                        this._setError(this.ERROR_TYPES.NODATA);
                    } else {
                        this.chainData = data.chain.map(item => {
                            item.options = item.options.sort(
                                (a, b) =>
                                    Number(a.strikePrice) -
                                    Number(b.strikePrice)
                            );
                            return item;
                        });
                        this.expireDate =
                            this.expireDate || this.chainData[0].expireDate;
                        if (this.expireDate) {
                            this.changeExpiration(this.expireDate);
                        } else {
                            this._setError(this.ERROR_TYPES.NODATA);
                        }
                    }
                })
                .catch(e => {
                    if (e.code === -2) {
                        this._setError();
                    } else {
                        this._setError(this.ERROR_TYPES.NODATA);
                    }
                    this.showLoading = false;
                });
        },
        changeExpiration(date) {
            if (date) {
                this.expireDate = date;
                if (this.chainData) {
                    this.showLoading = true;
                    const expiration = [];
                    let match = false;
                    this.chainData.forEach(d => {
                        expiration.push(d.expireDate);
                        if (d.expireDate === this.expireDate) {
                            match = true;
                            this._getSnapShot(d.options, expiration);
                        }
                    });
                    if (!match) {
                        this.expireDate = expiration[0];
                        this._getSnapShot(
                            this.chainData[0].options,
                            expiration
                        );
                    }
                }
            }
        },
        visibleRowsUpdate(rows) {
            const addedOptions = [];
            const oldOptionSecurities = utils.extend(
                true,
                {},
                this.optionSecurities
            );
            this.optionSecurities = {};
            rows.forEach(r => {
                this.optionSecurities[r.instrument] = r.type;
                if (!oldOptionSecurities[r.instrument]) {
                    addedOptions.push(r.instrument);
                }
            });
            const removedOptions = [];
            for (const instrument in oldOptionSecurities) {
                if (!this.optionSecurities[instrument]) {
                    removedOptions.push(instrument);
                }
            }

            this._unsubscribe(this.currentDataType, removedOptions);
            this._subscribe(addedOptions);
            this._startGreeksDataPolling();
        },
        changeGreeksView(view) {
            if (view === GREEKS_VIEW.HIDE_ALL) {
                this._stopGreeksDataPolling();
            }
        },
        hideSettingsPopover() {
            this.$emit('settings-popover-hide');
        },
        _setError(error) {
            this._unsubscribe(
                this.currentDataType,
                this._getAllSubscribeList()
            );
            this.error = error || this.ERROR_TYPES.INVALID_INVESTMENT;
            this.dataModel = {
                security: {},
                expiration: [],
                strikeRange: [],
                data: {}
            };
            this.showLoading = false;
        },
        _stopGreeksDataPolling() {
            if (this.greeksDataPolling) {
                window.clearInterval(this.greeksDataPolling);
                this.greeksDataPolling = null;
            }
        },
        _startGreeksDataPolling() {
            if (!this.mktdata) {
                return;
            }
            this._stopGreeksDataPolling();
            const { frequency } = this.mktdata.getOption();
            this.greeksDataPolling = window.setInterval(() => {
                this._getGreeksData(this._getOptionSubscribeList());
            }, frequency);
        },
        _getSnapShot(options, expiration) {
            const typeMapping = {};
            const securities = [];
            const rows = {};
            options.forEach(item => {
                const type = item.type.toLowerCase();
                rows[type] = rows[type] || [];
                rows[type].push({
                    id: item.instrument,
                    symbol: item.symbol.replace(/[a-zA-Z]*\s/g, ''),
                    type,
                    strike: +item.strikePrice
                });
                securities.push(item.instrument);
                typeMapping[item.instrument] = type;
            });
            if (securities.length && this.rootSecurity.quoteInstrument) {
                const promiseList = [
                    this.mktdata.quotes({
                        skipIdService: { 2: true },
                        securities: [
                            this._getSecurityQueryKey(this.rootSecurity)
                        ].concat(securities)
                    }),
                    this.mktdata
                        .optionGreeks({
                            instruments: securities,
                            rootInstrument: this.rootSecurity.quoteInstrument
                        })
                        .catch(e => {
                            // greek requests fails don't have to show error
                            return {};
                        })
                ];
                Promise.all(promiseList)
                    .then(res => {
                        const quotes = res[0];
                        const greeks = res[1];
                        const rootQueryKey = this._getSecurityQueryKey(
                            this.rootSecurity
                        );
                        const rootQuote = utils.find(
                            quotes,
                            quote => quote.security === rootQueryKey
                        );
                        this._fillOptionsQuotesData(quotes, rows, typeMapping);
                        this._fillGreeksData(greeks, rows, typeMapping);
                        this.dataModel = {
                            expiration,
                            data: rows,
                            security: this.rootSecurity
                        };
                        this._fillRootPriceData(rootQuote);
                        this.showLoading = false;
                        this.renderCompleted = true;
                    })
                    .catch(e => {
                        this._setError(this.ERROR_TYPES.NODATA);
                    });
            }
        },
        _getGreeksData(securities) {
            if (
                securities.length &&
                this.dataModel.security.quoteInstrument &&
                this.mktdata
            ) {
                this.mktdata
                    .optionGreeks({
                        instruments: securities,
                        rootInstrument: this.dataModel.security.quoteInstrument,
                        updateLastAccessTime: this.settings.updateLastAccessTime
                    })
                    .then(data => {
                        this._fillGreeksData(
                            data,
                            this.dataModel.data,
                            this.optionSecurities
                        );
                    })
                    .catch(e => {});
            }
        },
        updateData(security, updates) {
            const rootQueryKey = this._getSecurityQueryKey(this.rootSecurity);
            if (security === rootQueryKey) {
                this._fillRootPriceData({
                    quotes: updates
                });
            } else {
                const type = this.optionSecurities[security];
                if (this.dataModel.data[type]) {
                    const row = utils.find(
                        this.dataModel.data[type],
                        r => r.id === security
                    );
                    // Notices: _preProcessingChg function may be change the chg value.
                    this._preProcessingChg(updates);
                    this._fillDataModel(row, updates);
                }
            }
        },
        _subscribe(securities) {
            if (
                securities.length &&
                this[`${this.currentDataType}SubscribePromise`]
            ) {
                this.isSubscribing = true;
                this[`${this.currentDataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.subscribe(
                            securities,
                            this.subscribeListener,
                            {
                                updateLastAccessTime: this.settings
                                    .updateLastAccessTime,
                                skipIdService: {
                                    2: true
                                }
                            }
                        );
                    })
                    .catch(e => {});
            }
        },
        _unsubscribe(dataType, securities) {
            if (
                dataType &&
                securities.length &&
                this[`${dataType}SubscribePromise`]
            ) {
                this[`${dataType}SubscribePromise`]
                    .then(subscriber => {
                        subscriber.unsubscribe(
                            securities,
                            this.subscribeListener,
                            {
                                skipIdService: { 2: true }
                            }
                        );
                    })
                    .catch(e => {});
            }
        },
        _fillRootPriceData(d) {
            const rootLastPriceField = utils.find(
                d.quotes,
                q => q.name === 'lastPrice'
            );
            if (rootLastPriceField && rootLastPriceField.value) {
                this.$set(this.dataModel.data, 'root', {
                    lastPrice: +rootLastPriceField.value
                });
            }
        },
        _fillOptionsQuotesData(data, rows, typeMapping) {
            if (data && data.length) {
                data.forEach(d => {
                    if (
                        d.security !==
                        this._getSecurityQueryKey(this.rootSecurity)
                    ) {
                        const row = utils.find(
                            rows[typeMapping[d.security]],
                            r => r.id === d.security
                        );
                        // Notices: _preProcessingChg function may be change the chg value.
                        this._preProcessingChg(d.quotes);
                        this._fillDataModel(row, d.quotes);
                    }
                });
            }
        },
        _fillGreeksData(data, rows, typeMapping) {
            if (
                data.rootInstrument === this.rootSecurity.quoteInstrument &&
                data.greeks &&
                data.greeks.length
            ) {
                data.greeks.forEach(greek => {
                    const updates = [];
                    for (const id in greek) {
                        updates.push({
                            name: id,
                            value: greek[id]
                        });
                    }
                    const row = utils.find(
                        rows[typeMapping[greek.instrument]],
                        r => r.id === greek.instrument
                    );
                    this._fillDataModel(row, updates);
                });
            }
        },
        _fillDataModel(row, updates = []) {
            if (row && updates.length) {
                updates.forEach(item => {
                    let dataPoint = utils.find(
                        REGULAR_DATA_POINTS,
                        dp => dp.id === item.name
                    );
                    if (!dataPoint) {
                        dataPoint = utils.find(
                            GREEK_DATA_POINTS,
                            dp => dp.id === item.name
                        );
                    }
                    if (dataPoint) {
                        this.$set(
                            row,
                            item.name,
                            dataPoint.dataType === 'number' ||
                                dataPoint.dataType === 'int' ||
                                dataPoint.dataType === 'upDown'
                                ? +item.value
                                : item.value
                        );
                    }
                });
            }
        },
        /**
         * Pre process data to set the chg.
         * If do not have trade today, we need set chg to NaN.(it will display - in UI)
         * @param data {Array} - the quote data.
         * @private
         */
        _preProcessingChg(data) {
            const hasTradeToday = utils.find(
                data,
                fieldObj => fieldObj.name === HAS_TRADE_TODAY
            );
            const chgPosition = utils.findIndex(
                data,
                fieldObj => fieldObj.name === CHG
            );
            if (
                hasTradeToday &&
                hasTradeToday.value === NO_TRADE &&
                chgPosition > -1
            ) {
                data[chgPosition].value = NaN;
            }
        }
    },
    components: { OptionsUI }
};
</script>
